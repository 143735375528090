import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Responsive komponenter`}</h2>
    <p>{`Alle komponenter i designsystemet er responsive og tilpasser seg skjermstørrelsen. Dette er viktig for at designsystemet skal fungere på alle enheter, i nettlesere så vel som i native applikasjoner.`}</p>
    <h2>{`Breakpoints`}</h2>
    <p>{`Vi bruker et sett med breakpoints tilpasset de vanligste skjermstørrelsene for å definere hvordan innholdet skal se ut. Breakpointene er definert som Less-variabler i `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ffe-core`}</code>{` og kan brukes i media queries.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "less"
    }}><pre parentName="div" {...{
        "className": "language-less"
      }}><code parentName="pre" {...{
          "className": "language-less"
        }}><span parentName="code" {...{
            "className": "token variable"
          }}>{`@breakpoint-sm`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span></span>{` 480px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Mobil`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`@breakpoint-md`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span></span>{` 768px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Nettbrett`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`@breakpoint-lg`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span></span>{` 1024px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`     `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Laptop`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`@breakpoint-xl`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span></span>{` 1280px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`     `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Stor skjerm`}</span></code></pre></div>
    <h2>{`Mobile first`}</h2>
    <p>{`Breakpointene kan i prinsippet brukes på mange forskjellige måter. Vi har valgt å bruke en mobile first-tilnærming, som betyr at vi først designer for mobil og deretter legger til stiler eller overstyringer for større skjermer, i motsetning til å designe store layouter først og deretter fjerne stiler på små skjermer. I Less/CSS-sammenheng betyr dette at vi alltid bruker `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`min-width`}</code>{` i media queries. På denne måten unngår vi også potensielle konflikter mellom `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`min-width`}</code>{` og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`max-width`}</code>{`.`}</p>
    <p>{`Et klassisk eksempel er knapper. Her vil man ofte sette 100% bredde for å sikre stor nok klikkflate på mobil, og deretter overstyre denne med en fast bredde på større skjermer:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "less"
    }}><pre parentName="div" {...{
        "className": "language-less"
      }}><code parentName="pre" {...{
          "className": "language-less"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.button`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token atrule"
          }}>{`@media `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`min-width`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` @breakpoint-md`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.button`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` auto`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      